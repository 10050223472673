/* Toasts */

@keyframes wizard {
  0% {
    transform: translateY(100%);
  }

  30% {
    transform: translateY(32%);
  }

  80% {
    transform: translateY(32%);
  }

  92% {
    transform: translateY(-80%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes enter {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.animate-enter {
  animation: enter 0.2s ease-out;
}

@keyframes leave {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(0.9);
    opacity: 0;
  }
}

.animate-leave {
  animation: leave 0.15s ease-in forwards;
}

/* Other */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (hover: none) {
  /* disable safari long press preview */
  a {
    -webkit-touch-callout: none;
  }

  /* disable text selection */
  *:not(input, textarea) {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.scrollable {
  overflow-y: auto;
}

@media (max-width: 576px) and (display-mode: standalone) {
  /* Installed PWAs on mobile devices */

  @supports (-webkit-overflow-scrolling: touch) {
    /* Installed PWAs on mobile Apple devices */

    html,
    body {
     
    }

    .scrollable {
      height: 100svh;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      -webkit-scroll-behavior: none;
    }
  }
}
